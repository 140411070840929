import type { CaptureContext } from '@sentry/types';

export const captureException = (error: Error | unknown, captureContext?: CaptureContext) => {
  if ($sentry?.captureException) {
    return $sentry.captureException(error, captureContext);
  }
  console.error(error);

  return 'event-id';
};

export const captureMessage = (error: string, captureContext?: CaptureContext) => {
  if ($sentry?.captureMessage) {
    return $sentry.captureMessage(error, captureContext);
  }
  // eslint-disable-next-line no-console
  console.log(error);

  return 'event-id';
};
