import { Pagination } from '@/lib/goliath/goliath.types';

export const MAX_GOLIATH_OFFER_COUNT = 10000;

export function transformPagination(
  pagination: Pagination | null | undefined
): Pagination & { totalPages?: number } {
  if (!pagination || !pagination.size) return {};
  const maxPageCount = Math.round(MAX_GOLIATH_OFFER_COUNT / pagination.size);
  return {
    ...pagination,
    totalPages: Math.min(pagination.totalPages || 0, maxPageCount),
  };
}
