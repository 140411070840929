import { parse } from 'cookie';
const LOGIN_WITH_CRYPTOTICKET_PATH = '/tvpp-login';
const LOGIN_WITH_NAME_AND_KEY_PATH = '/key-login';
export const LOGIN_FAILED_PATH = '/cryptoticket-invalid';
const COOKIE_NAME = '_hardwaretool_session';

function checkSessionCookieExists({ cookie }: { cookie?: string | undefined }) {
  if (!cookie) return false;
  return parse(cookie)[COOKIE_NAME];
}

export default defineNuxtRouteMiddleware((to) => {
  const [path] = to.fullPath.split('?');

  // assets
  if (!process.server) {
    return;
  }

  // special treatment
  if (
    path === LOGIN_WITH_CRYPTOTICKET_PATH ||
    path === LOGIN_WITH_NAME_AND_KEY_PATH ||
    path === LOGIN_FAILED_PATH
  ) {
    return;
  }

  // all other urls mus have a valid cookie
  if (checkSessionCookieExists(useRequestHeaders(['cookie']))) {
    return;
  }

  return navigateTo(LOGIN_FAILED_PATH);
});
