<template>
  <NuxtLayout name="blank">
    <main>
      <!-- 404 -->
      <div
        v-if="errorStatus404"
        class="error error--404"
      >
        <div class="grid grid--center">
          <div class="grid__item grid__item--12 grid__item--l-6">
            <TextImage :text-image-data="ERROR_DATA.error404" />
          </div>
        </div>
      </div>

      <!-- 500 -->
      <div
        v-if="errorStatus500"
        class="error error--500"
      >
        <div class="grid grid--center">
          <div class="grid__item grid__item--12 grid__item--l-7">
            <TextImage :text-image-data="ERROR_DATA.error500" />
            <template v-if="isDev">
              <p>
                Message:<br /><strong>{{ error.message }}</strong>
              </p>

              <p v-if="parsedData?.url">
                Url:<br /><strong>{{ parsedData.url }}</strong>
              </p>

              <span>Stack:</span><br />
              <div
                style="overflow: auto; font-size: 0.8rem"
                v-html="parsedData?.stack || error?.stack"
              />
            </template>
          </div>
        </div>
      </div>
    </main>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { NuxtError } from 'nuxt/app';
import TextImage from '@/components/text-image.vue';
import { getPageHeader } from '@/helpers/page-header-compositon';

// Please refactor this! This has only been adopted from the content repo to
// make the whole project startable.
const ERROR_DATA = {
  error404: {
    theme: 'dark',
    align: 'center',
    imgPosition: 'top',
    altText: 'Entschuldigung',
    srcSmall: '/api/content/images/6b6e659e0167012699153cb014025ead/icon-404.png',
    content:
      '<h1>Entschuldigung</h1>\n\n<p>Ups, es ist wohl ein Fehler aufgetreten. Bitte schließen Sie diese Seite und melden Sie sich über TVPP an.</p>',
  },
  error500: {
    theme: 'dark',
    align: 'center',
    imgPosition: 'top',
    altText: 'Das T-Shop Hardware Tool ist nicht erreichbar',
    srcSmall: '/api/content/images/c8d9690adc45f0ed51753da7ea62569b/icon-500.png',
    content:
      '<h1>Das Tool ist nicht erreichbar</h1>\n\n<p>Wir wurden automatisch über den Fehler benachrichtigt. Bitte versuchen Sie, die Seite neu zu laden. Sollte dies nicht zum Erfolg führen, probieren Sie es bitte in wenigen Minuten erneut.</p>',
  },
  loginFailed: {
    theme: 'dark',
    align: 'center',
    imgPosition: 'top',
    altText: 'Das T-Shop Hardware Tool ist nicht erreichbar',
    srcSmall: '/api/content/images/6b6e659e0167012699153cb014025ead/icon-404.png',
    content:
      '<h1>Keine Berechtigung</h1>          <p> Leider konnten wir Sie nicht einloggen - bitte versuchen Sie es erneut über den Link </p> <p><span>Hardware-Vermarktung &gt;</span></p> <p> in TVPP.  </p>',
  },
};

useHead(
  getPageHeader({
    title: 'Fehler',
    isIndexed: false,
  })
);

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError,
  statusCode: {
    type: Number,
    default: 500,
  },
});

const runtimeConfig = useRuntimeConfig();

const isDev = !runtimeConfig.public.mode.isProduction;

const parsedData = computed(() => {
  try {
    return JSON.parse(props?.error?.data || '');
  } catch {
    return undefined;
  }
});
const errorStatus404 = computed(() => {
  return props.error?.statusCode === 404;
});
const errorStatus500 = computed(() => {
  return props.error?.statusCode !== 404;
});
</script>

<style scoped lang="scss">
.error {
  padding: 48px 0;

  &--500 {
    min-height: 100vh;
    border-top: 4px solid #e20074; // FIXME: Get color from variables
  }

  @media (min-width: 1024px) {
    // FIXME: Get breakpoint from variables
    padding: 72px 0;
  }
}
</style>
