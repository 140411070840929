import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { captureMessage } from '@/lib/sentry';

export type Category = {
  name: string;
  path: string;
};

export interface NavigationState {
  productCategories: Category[];
}

interface NavigationGettersTypes {
  productCategories: Category[];
}

export type NavigationGetterTree = {
  [P in keyof NavigationGettersTypes]: NavigationGettersTypes[P];
};

export type NavigationGetters = {
  [P in keyof NavigationGettersTypes]: (
    state: NavigationState,
    getters: NavigationGetterTree
  ) => NavigationGettersTypes[P];
};

export const state = (): NavigationState => ({
  productCategories: [],
});

export const mutations: MutationTree<NavigationState> = {
  SET_PRODUCT_CATEGORIES(state, productCategories: Category[]) {
    state.productCategories = productCategories;
  },
};

export const getters: GetterTree<NavigationState, NavigationState> & NavigationGetters = {
  productCategories(state) {
    return state.productCategories;
  },
};

export const actions: ActionTree<NavigationState, NavigationState> = {
  fetchCategories({ commit }) {
    return useFetch('/api/goliath/product-categories', { method: 'POST' })
      .then(({ data, error }) => {
        if (error.value) throw error.value;

        if (!data.value) {
          throw new Error('the property "entries" is missing in the response.');
        }

        const categories = data.value.map((e) => e?.productCategory);
        commit('SET_PRODUCT_CATEGORIES', categories);
      })
      .catch((error: Error | any) => {
        captureMessage('product categories could not be loaded', {
          contexts: {
            error,
          },
        });
      });
  },
};
