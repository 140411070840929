import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup(nuxtApp) {
    const config = nuxtApp.$config;

    Sentry.init({
      app: nuxtApp.vueApp,
      enabled: config.public.sentry.enabled,
      dsn: config.public.sentry.fakeDsn,
      integrations: [
        Sentry.browserTracingIntegration({ router: useRouter() }),
        Sentry.replayIntegration(),
      ],
      tunnel: '/api/sentry',
      environment: config.public.mode.name,

      tracePropagationTargets: ['localhost', config.public.baseUrl],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1,

      autoSessionTracking: false,
      sendClientReports: false,
      debug: false,
    });

    globalThis.$sentry = {
      // eslint-disable-next-line import/namespace
      captureException: Sentry.captureException,
      // eslint-disable-next-line import/namespace
      captureMessage: Sentry.captureMessage,
    };
  },
});
