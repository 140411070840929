import { ActionTree, GetterTree, MutationTree } from 'vuex';
import type { FetchError } from 'ofetch';
import { Cart, Item } from '@/lib/types/cartdata';
import { filter, fromPairs, groupBy, isEmpty } from 'lodash-es';
import ShopApi from '@/lib/shop-api';

export type TransitCart = {
  orderKey: string;
};

export interface CartState {
  cart: Cart | null;
  initialized: boolean;
  csrfToken: string | null;
  bundlesByArticleNumber: Record<string, Item[]>;
  transitCarts: TransitCart[];
}

interface CartGettersTypes {
  cartInitialized: boolean;
  cartItemsWithoutBundle: any[];
  cartQuantity: number;
  cartIsEmpty: boolean;
  cartData: Cart | null;
  cartItems: Item[];
  checkoutInitUrlSuffix: string;
  voucherInitialSum: number;
  voucherRestSum: number;
  getAnonymousCartData: Record<string, any>;
}

export type CartGetterTree = {
  [P in keyof CartGettersTypes]: CartGettersTypes[P];
};

export type CartGetters = {
  [P in keyof CartGettersTypes]: (state: CartState, getters: CartGetterTree) => CartGettersTypes[P];
};

function isRegularBundle(item: Item, state: CartState) {
  return Object.keys(state.bundlesByArticleNumber).includes(item.articleNumber);
}

const CHECKOUT_INIT_URLS = {
  upgrade: 'kundenformular/upgrade-identifikation',
  newCustomer: 'kundenformular/persoenliche-daten',
  existingCustomer: 'kundenformular',
};

export const state = (): CartState => ({
  cart: null,
  initialized: false,
  csrfToken: null,
  bundlesByArticleNumber: {},
  transitCarts: [],
});

export const mutations: MutationTree<CartState> = {
  SET_CART(state, cart: Cart) {
    state.cart = cart;
    state.initialized = cart !== null;
  },
  SET_CSRF_TOKEN(state, csrfToken: string) {
    state.csrfToken = csrfToken;
  },
  SET_TRANSIT_CARTS(state, carts) {
    state.transitCarts = carts;
  },
  ADD_PRODUCT_BUNDLES(state, productBundles: Record<string, Item[]>) {
    Object.keys(productBundles).forEach((articleNumber) => {
      state.bundlesByArticleNumber[articleNumber] = productBundles[articleNumber];
    });
  },
};

export const getters: GetterTree<CartState, CartState> & CartGetters = {
  cartInitialized(state) {
    return state.initialized;
  },
  cartItemsWithoutBundle(state, getters) {
    return getters.cartItems.filter((item) => !isRegularBundle(item, state));
  },
  cartQuantity(_state, getters) {
    return getters.cartItems.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0);
  },
  transitCarts(state) {
    return state.transitCarts || [];
  },
  transitCartsQuantity(state, getters) {
    return getters.transitCarts.length || 0;
  },
  cartIsEmpty(_state, getters) {
    return getters.cartQuantity === 0;
  },
  cartData(state): Cart | null {
    return state.cart && Object.keys(state.cart).length > 0 ? state.cart : null;
  },
  cartItems(_state, getters) {
    return getters.cartData?.items || [];
  },
  checkoutInitUrlSuffix() {
    return CHECKOUT_INIT_URLS.newCustomer;
  },
  hasMultipleBarcodes(_state, getters) {
    return getters.cartData?.barcodesCount > 1;
  },
  // Hardware Bundles: https://jira.i22.de/browse/LT-8
  voucherInitialSum(_state, getters) {
    return getters.cartData?.voucherInitialSum || 0;
  },
  voucherRestSum(_state, getters) {
    return getters.cartData?.voucherRestSum || 0;
  },
  addresses(_state, getters) {
    return getters.cartData?.addressesAttributes || [];
  },
  getAnonymousCartData(_state, getters): Record<string, any> {
    if (getters.cartData === null) return {};
    const validKeys = [
      'id',
      'orderKey',
      'state',
      'paymentType',
      'amount',
      'redeemedCoupon',
      'redeemedCouponDescription',
      'branding',
      'voucherRestSum',
      'voucherInitialSum',
      'items',
    ];
    return Object.fromEntries(
      Object.entries(getters.cartData).filter(([key]) => validKeys.includes(key))
    );
  },
};
export const actions: ActionTree<CartState, CartState> = {
  async initCart(context) {
    await context.dispatch('fetchTransitCarts');
    return context.dispatch('fetchCart');
  },
  clearCart(context) {
    context.commit('SET_CART', null);
    return ShopApi.resetCartAndOrder();
  },
  async fetchCart(context) {
    try {
      const data = await ShopApi.getCurrentCart();
      if (!data.order) return undefined;

      const allBundleSlugs = data.order.items
        .filter(({ bundleType }: Item) => bundleType === 'brodos')
        .map(({ slug }: Item) => slug);
      await context.dispatch('queryBrodosBundleSlugs', allBundleSlugs);
      context.commit('SET_CART', data.order);
      return data;
    } catch (error: FetchError | any) {
      if (error.status === 405) {
        return context.dispatch('clearCart');
      }
      console.error(error);
    }
  },
  async fetchTransitCarts(context) {
    try {
      const data = await ShopApi.vsCartsInTransit();
      context.commit('SET_TRANSIT_CARTS', data.inTransit);
      return data;
    } catch (error) {
      console.error('error in fetchTransitCarts:', error);
      return null;
    }
  },
  async receiveTransitCart(context, { cart }) {
    await ShopApi.vsReceiveCart(cart);
    const data = await ShopApi.getCurrentCart();
    context.commit('SET_CART', data.order);

    const carts = context.state.transitCarts.filter((c) => c.orderKey !== cart.orderKey);
    context.commit('SET_TRANSIT_CARTS', carts);

    return data;
  },
  async deleteTransitCart(context, { cart }) {
    try {
      const data = await ShopApi.vsDeleteCart(cart);

      const carts = context.state.transitCarts.filter((c) => c.orderKey !== cart.orderKey);
      context.commit('SET_TRANSIT_CARTS', carts);

      return data;
    } catch (error) {
      console.error('error in deleteTransitCart:', error);
      return null;
    }
  },
  async updateCartItem(context, { id, quantity }: { id: number; quantity: number }) {
    try {
      await ShopApi.updateItemInCart({ id, quantity });
    } finally {
      await context.dispatch('fetchCart');
    }
  },
  async deleteCartItem(context, { id }: { id: number }) {
    try {
      await ShopApi.removeItemFromCart({ id });
    } finally {
      await context.dispatch('fetchCart');
    }
  },
  async addCartItem(context, item: Item) {
    let quantity = item.quantity === 0 ? 0 : item.quantity || 1;
    const articleNumber = item.articleNumber || undefined;
    const existingItem = context.getters.cartItems.find(
      (cartItem: Item) => cartItem.articleNumber === articleNumber
    );

    if (existingItem) {
      let response;
      quantity += existingItem.quantity;
      if (quantity <= 0) {
        response = await ShopApi.removeItemFromCart({ id: existingItem.id });
      } else {
        response = await ShopApi.updateItemInCart({
          id: existingItem.id,
          quantity,
        });
      }
      await context.dispatch('fetchCart');
      return response;
    }

    const response = await ShopApi.addItemToCart({ articleNumber, quantity });
    await context.dispatch('fetchCart');
    return response;
  },
  async cartAddCoupon(context, { redeemedCoupon }: { redeemedCoupon: string }) {
    try {
      await ShopApi.addCouponToCart({ redeemedCoupon });
    } finally {
      await context.dispatch('fetchCart');
    }
  },
  async updateCustomerData(context, order: Partial<Cart>) {
    const data = await ShopApi.updateCustomerData(order);
    await context.dispatch('fetchCart');
    return data;
  },
  resetCustomer() {
    return ShopApi.resetCustomer();
  },
  async cartAddCouponAndItem(context, { redeemedCoupon, contractNumber }) {
    const response = await ShopApi.addCouponAndItem(redeemedCoupon, contractNumber);
    await context.dispatch('fetchCart');
    return response;
  },
  async queryBrodosBundleSlugs(context, bundleSlugs: string[]) {
    const bundleUpdatesList = await Promise.all(
      bundleSlugs.map(async (bundleSlug) => {
        if (context.state.bundlesByArticleNumber[bundleSlug]) return [];

        const productBundle = await $fetch('/api/goliath/offer', {
          method: 'POST',
          body: { productSlug: bundleSlug },
        });

        if (!productBundle?.product || !('bundledProducts' in productBundle.product)) return [];

        const { articleNumber, bundledProducts } = productBundle.product;

        if (!bundledProducts) return [];

        const itemsGroupedByAN = groupBy(bundledProducts, 'articleNumber');
        const countedItems = Object.values(itemsGroupedByAN).map((group) => ({
          ...group[0],
          count: group.length,
        }));

        return [articleNumber, countedItems];
      })
    );

    // [[], ['some', 'entry'] => {'some': 'entry'}
    const bundleUpdates = fromPairs(filter(bundleUpdatesList, (l) => !isEmpty(l)));

    if (isEmpty(bundleUpdates)) return;
    context.commit('ADD_PRODUCT_BUNDLES', bundleUpdates);
  },
};
