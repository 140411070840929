import { GetterTree, MutationTree } from 'vuex';

export type Staggered = {
  amount: number;
  percentage: number;
};

export type Rebate = {
  name?: string;
  description?: string | undefined;
  footnote?: string;
  staggereds?: Staggered[];
};

export type Rebates = Record<string, Rebate>;

export interface OfferState {
  rebates: Rebates;
}

interface OfferGettersTypes {
  rebates: Rebates;
}

export type OfferGetterTree = {
  [P in keyof OfferGettersTypes]: OfferGettersTypes[P];
};

export type OfferGetters = {
  [P in keyof OfferGettersTypes]: (
    state: OfferState,
    getters: OfferGetterTree
  ) => OfferGettersTypes[P];
};

export const state = (): OfferState => ({
  rebates: {},
});

export const mutations: MutationTree<OfferState> = {
  SET_REBATES(state, rebates: Rebates) {
    state.rebates = rebates;
  },
};

export const getters: GetterTree<OfferState, OfferState> & OfferGetters = {
  rebates(state) {
    return state.rebates;
  },
};
