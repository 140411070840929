import type { Cart } from '@/lib/types/cartdata';
import type { TransitCart } from '@/store/cart';

const backendApiUrl = '/api/shop';

export const endpoints = {
  order: `${backendApiUrl}/order`,
  bundleItems: `${backendApiUrl}/bundle_items`,
  confirmation: `${backendApiUrl}/confirmation`,
  coupon: `${backendApiUrl}/coupon`,
  couponItems: `${backendApiUrl}/coupon_items`, // we should rename this oO
  downtimes: `${backendApiUrl}/downtimes`,
  homebaseUpgrade: `${backendApiUrl}/homebase_upgrade`,
  items: `${backendApiUrl}/items`,
  item: (id: number) => `${backendApiUrl}/items/${Number(id)}`,
  customerReset: `${backendApiUrl}/reset_customer`,
  faqCategory: `${backendApiUrl}/faq/category`,
  faqSearch: `${backendApiUrl}/faq/search`,
  tvppParameter: `${backendApiUrl}/tvpp_parameter`,
  shacUpgrade: `${backendApiUrl}/shac_validate_upgrade`,
  // FIXME: move away
  resetCart: `${backendApiUrl}/reset_cart`,
  vsCartsInTransit: `${backendApiUrl}/orders/virtual_shelf/in_transit_to_tool`,
  vsReceiveCart: `${backendApiUrl}/orders/virtual_shelf/receive`,
  vsDeleteCart: (orderKey: string) => `${backendApiUrl}/orders/virtual_shelf/delete/${orderKey}`,
  paypalCheckout: `${backendApiUrl}/paypal_checkout`,
};

const apiCalls = {
  paypalCheckout() {
    return $shopApi(endpoints.paypalCheckout, { method: 'POST' });
  },
  submitOrder(order: {
    brodosSuccessUrl?: string;
    brodosFailureUrl?: string;
    confirmationUrl?: string;
    failureUrl?: string;
    kek?: boolean;
  }) {
    return $shopApi(endpoints.order, { method: 'POST', body: order });
  },
  confirmOrder() {
    return $shopApi(endpoints.confirmation);
  },
  getCurrentCart(): Promise<{ order: Cart }> {
    return $shopApi(endpoints.order);
  },
  addItemToCart({ quantity, articleNumber }: { quantity: number; articleNumber?: string }) {
    return $shopApi(endpoints.items, {
      method: 'POST',
      body: {
        item: { quantity, articleNumber },
      },
    });
  },
  updateItemInCart({ id, quantity }: { id: number; quantity: number }) {
    return $shopApi(endpoints.item(id), { method: 'PATCH', body: { quantity } });
  },
  removeItemFromCart({ id }: { id: number }) {
    return $shopApi(endpoints.item(id), { method: 'DELETE' });
  },
  addCouponToCart({ redeemedCoupon }: { redeemedCoupon: string }) {
    return $shopApi(endpoints.coupon, { method: 'PATCH', body: { order: { redeemedCoupon } } });
  },
  updateCustomerData(order: Partial<Cart>) {
    return $shopApi(endpoints.order, { method: 'PATCH', body: { order } });
  },
  resetCustomer() {
    return $shopApi(endpoints.customerReset, {
      method: 'PATCH',
      body: {
        order: { reset_tvpp_customer: true },
      },
    });
  },
  addCouponAndItem(redeemedCoupon: string, contractNumber: string) {
    return $shopApi(endpoints.couponItems, {
      method: 'POST',
      body: {
        item: { contractNumber, quantity: 1 },
        order: { redeemedCoupon },
      },
    });
  },
  resetCartAndOrder() {
    return $shopApi(endpoints.resetCart, { method: 'POST' });
  },
  vsCartsInTransit(): Promise<{ inTransit: TransitCart[] }> {
    // @ts-ignore
    return $shopApi(endpoints.vsCartsInTransit, { loadingSpinner: false });
  },
  vsReceiveCart(order: Cart) {
    return $shopApi(endpoints.vsReceiveCart, {
      method: 'PUT',
      body: { order_key: order.orderKey },
    });
  },
  vsDeleteCart(order: Cart) {
    return $shopApi(endpoints.vsDeleteCart(order.orderKey), { method: 'DELETE' });
  },
};

export { apiCalls as default };
