// eslint-disable-next-line import/no-named-as-default
import getFeatures, { Feature } from '@i22/status-toolbar/dist/saved-features';
import { Mode } from '@/lib/mode-setup';

export type Features = Record<string, Feature> & {
  defaultFeatures: Record<string, Feature>;
};

export default (mode: Mode) => {
  const onlyDevelopment = mode.name === 'development';
  const onlyDevelopmentAndPreview = !(mode.isProduction || mode.name === 'staging');

  const features: Record<string, Feature> = {
    test: { enabled: false },
  };

  return getFeatures(features, mode.isProduction) as Features;
};
