<template>
  <figure
    v-if="!renderWithoutFigure"
    :id="id"
    :class="containerClass"
  >
    <picture :class="pictureClass">
      <source
        v-if="srcLarge"
        :srcset="srcLarge"
        :media="showMediaLargeFrom"
      />
      <source
        v-if="srcMedium"
        :srcset="srcMedium"
        :media="showMediaMediumFrom"
      />
      <img
        tabindex="-1"
        :src="src"
        :alt="alt"
        :class="imageClass"
        :height="height"
        :width="width"
      />
    </picture>
    <slot></slot>
  </figure>
  <img
    v-else
    :id="id"
    tabindex="-1"
    :src="src"
    :alt="alt"
    :class="imageClass"
    :height="height"
    :width="width"
  />
</template>

<script setup>
const props = defineProps({
  renderWithoutFigure: Boolean,
  srcLarge: { type: String, default: null },
  srcMedium: { type: String, default: null },
  mediaSrcLarge: {
    type: Number,
    default: 980,
  },
  mediaSrcMedium: {
    type: Number,
    default: 451,
  },
  imageClass: { type: String, default: null },
  pictureClass: { type: String, default: null },
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: true,
  },
  align: { type: String, default: null },
  id: { type: String, default: null },
  height: { type: Number, default: null },
  width: { type: Number, default: null },
  customClass: { type: String, default: null },
  spaces: Boolean,
});

const showMediaLargeFrom = computed(() => `(min-width: ${props.mediaSrcLarge}px)`);
const showMediaMediumFrom = computed(() => `(min-width: ${props.mediaSrcMedium}px)`);
const containerClass = computed(() => {
  const modifier = [];
  modifier.push('cdn-image');
  if (props.align) modifier.push(`cdn-image--${props.align}`);
  if (props.spaces) modifier.push('cdn-image--spaces');
  if (props.customClass) modifier.push(props.customClass);
  return modifier;
});
</script>

<style lang="scss">
.cdn-image {
  &--spaces {
    margin-bottom: 18px;
    // FIXME with: @include mq($mq-large) {}
    @media screen and (min-width: 1024px) {
      margin-bottom: 56px;
    }
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
  &--left {
    text-align: left;
  }
}
</style>
