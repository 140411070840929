import {
  backdropVuexStore,
  cartVuexStore,
  downtimesVuexStore,
  navigationVuexStore,
  offersVuexStore,
  serverDateVuexStore 
} from "#imports"
const VuexStore = {
  modules: {
      backdrop: {
        ...backdropVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      cart: {
        ...cartVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      downtimes: {
        ...downtimesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      navigation: {
        ...navigationVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      offers: {
        ...offersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      serverDate: {
        ...serverDateVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore