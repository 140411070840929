<template>
  <div
    :class="textImageClass"
    class="text-image"
  >
    <CdnImage
      v-if="textImageData.srcSmall"
      :alt="textImageData.altText"
      :class="imageHeight"
      :image-class="'text-image__img'"
      :src="textImageData.srcSmall"
      :src-lg="textImageData.srcLarge"
      :src-sm="textImageData.srcMedium"
      class="text-image__image"
    />

    <div
      v-if="textImageData.content"
      :class="[textClass, 'text-image__content']"
      class=""
    >
      <div v-html="textImageData.content"></div>
      <slot :name="'content'"></slot>
    </div>
  </div>
</template>

<script>
import CdnImage from '@/components/cdn-image.vue';

export default {
  name: 'TextImage',
  /**
   * Component props
   * @type {Object}
   */
  props: {
    textImageData: {
      type: Object,
      default: null,
    },
    noImage: {
      type: Boolean,
      default: false,
    },
    article: {
      type: Boolean,
      default: false,
    },
    hasMargin: {
      type: Boolean,
      default: true,
    },
    fixHeight: {
      type: Boolean,
      default: false,
    },
    mobileAlign: {
      type: String,
      default: '',
    },
  },
  components: {
    CdnImage,
  },
  computed: {
    imageHeight() {
      return {
        'text-image__image-fix-height': this.fixHeight,
      };
    },
    textImageClass() {
      const modifier = [];
      if (this.textImageData.theme) modifier.push(`text-image--theme-${this.textImageData.theme}`);
      if (this.textImageData.textSize)
        modifier.push(`text-image--text-${this.textImageData.textSize}`);
      if (this.textImageData.imgPosition)
        modifier.push(`text-image--img-${this.textImageData.imgPosition}`);
      if (this.textImageData.align) modifier.push(`text-image--align-${this.textImageData.align}`);
      if (this.textImageData.invertTheme) modifier.push('theme-inverted');
      if (this.noImage) modifier.push('text-image--no-image');
      if (this.article) modifier.push('text-image--article');
      if (!this.hasMargin) modifier.push('text-image--no-margin');
      return modifier;
    },
    imageClass() {
      const modifier = [];
      modifier.push(this.textImageData.extraClass);
      if (this.textImageData.flipX) modifier.push('text-image__img--flip-x');
      if (this.textImageData.flipY) modifier.push('text-image__img--flip-y');
      return modifier;
    },
    textClass() {
      const modifier = [];
      if (this.mobileAlign.length > 0) {
        modifier.push(` text-image__content-m-${this.mobileAlign}`);
      }
      return modifier;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'assets/base';

$m: '.text-image';

#{$m} {
  display: flex;
  margin-left: auto;
  margin-top: $grid-base * 1.5;
  margin-bottom: $grid-base * 2;
  flex-direction: column;

  @include mq($mq-md) {
    margin-bottom: $grid-base * 3;
  }

  //If article place image beneath text in mobile
  &--article {
    flex-direction: column-reverse;
    .text-image__image {
      margin-bottom: 0;
    }

    .text--h2 {
      margin-bottom: 36px;
    }
  }

  &__img {
    &--flip-x {
      transform: scaleX(-1);
    }
    &--flip-y {
      transform: scaleY(-1);
    }
  }

  // Text Image style
  &--img-left,
  &--img-right {
    #{$m}__content {
      @include mq($mq-large) {
        width: 48.5%;
      }
    }

    #{$m}__image {
      @include mq($mq-large) {
        width: 51.5%;
      }
    }
  }

  &--img-left {
    #{$m}__content {
      @include mq($mq-large) {
        padding-left: $grid-base * 5;
      }
    }

    #{$m}__image {
      text-align: center;
      @include mq($mq-large) {
        text-align: left;
        padding-right: $grid-base * 1.5;
        padding-bottom: 0;
      }
    }
    //general Alignment
    @include mq($mq-large) {
      flex-direction: row;
    }
  }

  &--img-right {
    #{$m}__content {
      @include mq($mq-large) {
        padding-right: $grid-base * 5;
      }
    }

    #{$m}__image {
      text-align: center;
      @include mq($mq-large) {
        text-align: right;
        padding-left: $grid-base * 1.5;
        padding-bottom: 0;
      }
    }
    //general Alignment
    @include mq($mq-large) {
      flex-direction: row-reverse;
    }
  }

  // Image bottom
  &--img-bottom {
    flex-direction: column-reverse;
  }

  // Image Top
  &--img-top {
    #{$m}__content {
      @include mq($mq-sm) {
        padding-top: $grid-base * 2;
      }
    }
  }

  // Text align content
  &--align-center {
    text-align: center;
  }

  &--align-right {
    #{$m}__content {
      text-align: right;
    }
  }
  &--align-left {
    #{$m}__content {
      text-align: left;
    }
    @include mq(false, $mq-medium - 1) {
      #{$m}__content-m {
        &-center {
          text-align: center;
        }
      }
    }
  }

  // Theme color
  &--theme-light {
    color: $color-secondary;
  }
  &--theme-dark {
    color: $color-font;
    @include mq(false, $mq-medium - 1) {
      &.theme-inverted {
        color: $color-secondary;
      }
    }
  }

  // Text Size
  &--text-large {
    // RTE only for this component
    p {
      @include font-size(18px, 27px);
      @include mq($mq-medium) {
        @include font-size(21px, 27px);
      }
    }
  }

  // Text Content - title, copy, link, icon
  &__title {
    &--italic {
      font-style: italic;
    }
    &--tour {
      margin-bottom: $grid-base * 3;
    }
  }
  &__content {
    width: 100%;
    @include mq($mq-sm) {
      padding-top: $grid-base * 2;
    }
  }

  &__image {
    width: 100%;
    flex-shrink: 0; //IE Fix
    margin-bottom: $grid-base * 1.5;
    @include mq($mq-md) {
      margin-bottom: 0;
    }
    &-fix-height {
      min-height: 105px;
    }
  }

  &__icon-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &--no-image {
    #{$m}__content {
      padding: 0;
    }
  }

  &--no-margin {
    margin: 0;
    @include mq($mq-large) {
      margin: 0;
    }
  }
  &.text-image {
    &--no-margin {
      &--bottom {
        margin-bottom: 0; // necessary override for tab image
      }
    }
  }
}
</style>
