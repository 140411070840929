import { ActionTree, GetterTree, MutationTree } from 'vuex';

type State = () => {
  date: Date;
};

export const state: State = () => ({
  date: new Date(),
});

export type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  SET_SERVER_DATE(state) {
    state.date = new Date();
  },
};

export const actions: ActionTree<RootState, RootState> = {
  setServerDate({ commit }) {
    commit('SET_SERVER_DATE');
  },
};

export const getters: GetterTree<RootState, RootState> = {
  getServerDate(state) {
    return state.date;
  },
};
