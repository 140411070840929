<template>
  <component
    :is="nonReactive"
    v-if="nonReactive"
  />
</template>
<script lang="ts" setup>
/* eslint-disable no-param-reassign */
import { computed, VNode, PropType, ComponentOptionsBase } from 'vue';

const props = defineProps({
  image: {
    type: Object as PropType<ComponentOptionsBase>,
    required: true,
  },
});

const ensureViewbox = (svg: VNode) => {
  if (svg.props?.viewBox) return;
  if (!svg.props?.width || !svg.props?.height) {
    console.error(`an svg must have a viewBox or width & height!`, svg);
    return;
  }

  const w = svg.props.width.replace('px', '') || 0;
  const h = svg.props.height.replace('px', '') || 0;
  svg.props.viewBox = `0 0 ${w.trim()} ${h.trim()}`;
};

// Remove width & height to make icons full scalable.
const removeDimensions = (svg: VNode) => {
  delete svg.props?.width;
  delete svg.props?.height;
};

const nonReactive = computed(() => {
  try {
    const image = props.image?.render?.(undefined, []);
    if (!image) return undefined;
    ensureViewbox(image);
    removeDimensions(image);
    return image;
  } catch (e) {
    console.error(e);
  }
  return undefined;
});
</script>
