import { default as cryptoticket_45invalidemkiSfWEY1Meta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/cryptoticket-invalid.vue?macro=true";
import { default as _91_slug_93fdHkXP1VpcMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/geraete/[_slug].vue?macro=true";
import { default as indexcCbW5zQKRNMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/index.vue?macro=true";
import { default as maintenancecYJR3Ib0chMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/maintenance.vue?macro=true";
import { default as routes_45info3eEvgfp4doMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/routes-info.vue?macro=true";
import { default as bestaetigungqEufkMf8UOMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/bestaetigung.vue?macro=true";
import { default as fehler7ow30oNkDiMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/fehler.vue?macro=true";
import { default as persoenliche_45datenPrCCVIMeQCMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/kundenformular/persoenliche-daten.vue?macro=true";
import { default as kundenformularQK0fbtysWrMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/kundenformular.vue?macro=true";
import { default as warenkorb_45virtuelles_45regal1iY7lOnlBbMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/warenkorb-virtuelles-regal.vue?macro=true";
import { default as warenkorbyr1cLf8ZKYMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/warenkorb.vue?macro=true";
import { default as zusammenfassungJEuJW2sCbxMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/zusammenfassung.vue?macro=true";
import { default as _91_46_46_46config_93c4fQ6gSOkAMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/suche/[...config].vue?macro=true";
import { default as transform_45paginationJ5PYSGhpJfMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/suche/transform-pagination.ts?macro=true";
export default [
  {
    name: cryptoticket_45invalidemkiSfWEY1Meta?.name ?? "cryptoticket-invalid",
    path: cryptoticket_45invalidemkiSfWEY1Meta?.path ?? "/cryptoticket-invalid",
    meta: cryptoticket_45invalidemkiSfWEY1Meta || {},
    alias: cryptoticket_45invalidemkiSfWEY1Meta?.alias || [],
    redirect: cryptoticket_45invalidemkiSfWEY1Meta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/cryptoticket-invalid.vue").then(m => m.default || m)
  },
  {
    name: _91_slug_93fdHkXP1VpcMeta?.name ?? "geraete-_slug",
    path: _91_slug_93fdHkXP1VpcMeta?.path ?? "/geraete/:_slug()",
    meta: _91_slug_93fdHkXP1VpcMeta || {},
    alias: _91_slug_93fdHkXP1VpcMeta?.alias || [],
    redirect: _91_slug_93fdHkXP1VpcMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/geraete/[_slug].vue").then(m => m.default || m)
  },
  {
    name: indexcCbW5zQKRNMeta?.name ?? "index",
    path: indexcCbW5zQKRNMeta?.path ?? "/",
    meta: indexcCbW5zQKRNMeta || {},
    alias: indexcCbW5zQKRNMeta?.alias || [],
    redirect: indexcCbW5zQKRNMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenancecYJR3Ib0chMeta?.name ?? "maintenance",
    path: maintenancecYJR3Ib0chMeta?.path ?? "/maintenance",
    meta: maintenancecYJR3Ib0chMeta || {},
    alias: maintenancecYJR3Ib0chMeta?.alias || [],
    redirect: maintenancecYJR3Ib0chMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: routes_45info3eEvgfp4doMeta?.name ?? "routes-info",
    path: routes_45info3eEvgfp4doMeta?.path ?? "/routes-info",
    meta: routes_45info3eEvgfp4doMeta || {},
    alias: routes_45info3eEvgfp4doMeta?.alias || [],
    redirect: routes_45info3eEvgfp4doMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/routes-info.vue").then(m => m.default || m)
  },
  {
    name: bestaetigungqEufkMf8UOMeta?.name ?? "shop-bestaetigung",
    path: bestaetigungqEufkMf8UOMeta?.path ?? "/shop/bestaetigung",
    meta: bestaetigungqEufkMf8UOMeta || {},
    alias: bestaetigungqEufkMf8UOMeta?.alias || [],
    redirect: bestaetigungqEufkMf8UOMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/bestaetigung.vue").then(m => m.default || m)
  },
  {
    name: fehler7ow30oNkDiMeta?.name ?? "shop-fehler",
    path: fehler7ow30oNkDiMeta?.path ?? "/shop/fehler",
    meta: fehler7ow30oNkDiMeta || {},
    alias: fehler7ow30oNkDiMeta?.alias || [],
    redirect: fehler7ow30oNkDiMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/fehler.vue").then(m => m.default || m)
  },
  {
    name: kundenformularQK0fbtysWrMeta?.name ?? "shop-kundenformular",
    path: kundenformularQK0fbtysWrMeta?.path ?? "/shop/kundenformular",
    meta: kundenformularQK0fbtysWrMeta || {},
    alias: kundenformularQK0fbtysWrMeta?.alias || [],
    redirect: kundenformularQK0fbtysWrMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/kundenformular.vue").then(m => m.default || m),
    children: [
  {
    name: persoenliche_45datenPrCCVIMeQCMeta?.name ?? "shop-kundenformular-persoenliche-daten",
    path: persoenliche_45datenPrCCVIMeQCMeta?.path ?? "persoenliche-daten",
    meta: persoenliche_45datenPrCCVIMeQCMeta || {},
    alias: persoenliche_45datenPrCCVIMeQCMeta?.alias || [],
    redirect: persoenliche_45datenPrCCVIMeQCMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/kundenformular/persoenliche-daten.vue").then(m => m.default || m)
  }
]
  },
  {
    name: warenkorb_45virtuelles_45regal1iY7lOnlBbMeta?.name ?? "shop-warenkorb-virtuelles-regal",
    path: warenkorb_45virtuelles_45regal1iY7lOnlBbMeta?.path ?? "/shop/warenkorb-virtuelles-regal",
    meta: warenkorb_45virtuelles_45regal1iY7lOnlBbMeta || {},
    alias: warenkorb_45virtuelles_45regal1iY7lOnlBbMeta?.alias || [],
    redirect: warenkorb_45virtuelles_45regal1iY7lOnlBbMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/warenkorb-virtuelles-regal.vue").then(m => m.default || m)
  },
  {
    name: warenkorbyr1cLf8ZKYMeta?.name ?? "shop-warenkorb",
    path: warenkorbyr1cLf8ZKYMeta?.path ?? "/shop/warenkorb",
    meta: warenkorbyr1cLf8ZKYMeta || {},
    alias: warenkorbyr1cLf8ZKYMeta?.alias || [],
    redirect: warenkorbyr1cLf8ZKYMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/warenkorb.vue").then(m => m.default || m)
  },
  {
    name: zusammenfassungJEuJW2sCbxMeta?.name ?? "shop-zusammenfassung",
    path: zusammenfassungJEuJW2sCbxMeta?.path ?? "/shop/zusammenfassung",
    meta: zusammenfassungJEuJW2sCbxMeta || {},
    alias: zusammenfassungJEuJW2sCbxMeta?.alias || [],
    redirect: zusammenfassungJEuJW2sCbxMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/shop/zusammenfassung.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46config_93c4fQ6gSOkAMeta?.name ?? "suche-config",
    path: _91_46_46_46config_93c4fQ6gSOkAMeta?.path ?? "/suche/:config(.*)*",
    meta: _91_46_46_46config_93c4fQ6gSOkAMeta || {},
    alias: _91_46_46_46config_93c4fQ6gSOkAMeta?.alias || [],
    redirect: _91_46_46_46config_93c4fQ6gSOkAMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/suche/[...config].vue").then(m => m.default || m)
  },
  {
    name: transform_45paginationJ5PYSGhpJfMeta?.name ?? "suche-transform-pagination",
    path: transform_45paginationJ5PYSGhpJfMeta?.path ?? "/suche/transform-pagination",
    meta: transform_45paginationJ5PYSGhpJfMeta || {},
    alias: transform_45paginationJ5PYSGhpJfMeta?.alias || [],
    redirect: transform_45paginationJ5PYSGhpJfMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240910082735/pages/suche/transform-pagination.ts").then(m => m.default || m)
  }
]