import { setConfig } from '@i22-td-smarthome/component-library';
// @ts-ignore
import { NuxtLink } from '#components';

// Set runtime env to component lib.
export default defineNuxtPlugin((nuxtApp) => {
  setConfig({
    cloudFront: nuxtApp.$config.public.cloudFront,
    nuxtLink: NuxtLink,
  });
});
