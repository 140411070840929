import { ref } from 'vue';
import featureLib from '@/lib/features';

/**
 *
 * To avoid SSR / CSR mismatch,
 * we need the "defaultFeatures" at page-load and set the custom (LocalStorage) features after the page is loaded.
 * This Plugin is called one time per App- / Page-loaded and will load all saved Features from LocalStorage.
 *
 * When using what:
 *
 * featuresClient: in client-side-only code
 * featuresAsync: In template, for computed props, etc. It is reactive, so you'll maybe need a watcher!
 *
 * When you are not sure: use featuresAsync!
 */

let calledOnetime = false;

export default defineNuxtPlugin((nuxtApp) => {
  const features = featureLib(nuxtApp.$config.public.mode);
  const featuresAsync = ref(JSON.parse(JSON.stringify(features.defaultFeatures)));
  const featuresClient = ref(JSON.parse(JSON.stringify(features)));

  nuxtApp.vueApp.provide('featuresAsync', featuresAsync);
  nuxtApp.vueApp.provide('featuresClient', featuresClient);

  nuxtApp.hook('app:mounted', () => {
    if (process.client) {
      setTimeout(() => {
        if (calledOnetime) return;
        calledOnetime = true;
        featuresAsync.value = features;
      }, 100);
    }
  });
});
