import { GetterTree, ActionTree, MutationTree } from 'vuex';

export interface BackdropState {
  active: number;
  global: number;
}

interface BackdropGettersTypes {
  isActive: boolean;
  isGloballyActive: boolean;
}

export type BackdropGetterTree = {
  [P in keyof BackdropGettersTypes]: BackdropGettersTypes[P];
};

export type BackdropGetters = {
  [P in keyof BackdropGettersTypes]: (
    state: BackdropState,
    getters: BackdropGetterTree
  ) => BackdropGettersTypes[P];
};

export const state = (): BackdropState => ({
  active: 0,
  global: 0,
});

export const mutations: MutationTree<BackdropState> = {
  SHOW(state, options: { global?: boolean } = {}) {
    state.active += 1;
    if (options.global) {
      state.global += 1;
    }
  },
  HIDE(state, options: { global?: boolean } = {}) {
    if (state.active > 0) {
      state.active -= 1;
    }
    if (options.global && state.global > 0) {
      state.global -= 1;
    }
  },
};

export const actions: ActionTree<BackdropState, BackdropState> = {};

export const getters: GetterTree<BackdropState, BackdropState> & BackdropGetters = {
  isActive(state) {
    return state.active > 0;
  },
  isGloballyActive(state) {
    return state.global > 0;
  },
};
